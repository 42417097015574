<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <section class="gray pt-4">
        <div class="container">
          <small class="float-end">Updated at 2022-11-02</small>
          <h2>Return And Refund Policy</h2>
          <div class="card">
            <div class="card-body">
              <div>
                <h4>
                  Definitions and key terms
                </h4>
                <p>
                  To help explain things as clearly as possible in this Return
                  &amp; Refund Policy, every time any of these terms are
                  referenced, are strictly defined as:
                </p> 
                <p>
                  Cookie: small amount of data generated by a website and saved
                  by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your
                  language preference or login information.
                </p>
                <p>
                  Company: when this policy mentions “Company,” “we,” “us,” or
                  “our,” it refers to Realhub, that is responsible for your information under this Return &amp; Refund Policy.
                </p> 
                <p>
                  Customer: refers to the company, organization or person that
                  signs up to use the Realhub Service to manage the 
                  relationships with your consumers or service users.
                </p> 
                <p>
                  Device: any internet connected device such as a phone, tablet,
                  computer or any other device that can be used to visit 
                  Realhub and use the services.
                </p> 
                <p>
                  Service: refers to the service provided by Realhub as
                  described in the relative terms (if available) and on this
                  platform.
                </p> 
                <p>
                  Website: Realhub.&quot;’s&quot; site, which can be accessed
                  via this URL: https://realhub.co.ke/
                </p> 
                <p>
                  You: a person or entity that is registered with Realhub to use
                  the Services.
                </p>
                <h4>
                  Return &amp; Refund Policy
                </h4>
                <p>
                  Thanks for shopping at Realhub. We appreciate the fact that
                  you like to buy the stuff we build. We also want to make sure
                  you 
                  have a rewarding experience while you’re exploring,
                  evaluating, and purchasing our products.
                </p>
                <p>
                  As with any shopping experience, there are terms and
                  conditions that apply to transactions at Realhub. We’ll be as
                  brief as our 
                  attorneys will allow. The main thing to remember is that by
                  placing an order or making a purchase at Realhub, you agree to
                  the 
                  terms set forth below along with Policy.
                </p>
                <p>
                  If there’s something wrong with the you bought, or if you are
                  not happy with it, you have 1 week to issue a refund and
                  return your item.</p>
                <p>
                  If you would like to return a product, the only way would be
                  if you follow the next guidelines:
                </p>
                <p>
                  The package needs to come back with the original receipt of
                  the purchase.
                </p> 
                <p>
                  The product has to be in the packaging we sent in the first
                  place.
                </p> 
                <p>
                  The product has to be damage free, if we find any damage on
                  the product we will cancel your refund immediately.
                </p>
                <h4>
                  Refunds
                </h4>
                <p>
                  We at ourselves to serving our customers with the best
                  products. Every single product that you choose is thoroughly
                  inspected, 
                  checked for defects and packaged with utmost care. We do this
                  to ensure that you fall in love with our products.
                </p>
                <p>
                  Sadly, there are times when we may not have the product(s)
                  that you choose in stock, or may face some issues with our 
                  inventory and quality check. In such cases, we may have to
                  cancel your order. You will be intimated about it in advance
                  so that 
                  you don&apos;t have to worry unnecessarily about your order.
                  If you have purchased via Online payment (not Cash on
                  Delivery), then 
                  you will be refunded once our team confirms your request.
                </p> 
                <p>
                  We carry out thorough quality check before processing the
                  ordered item. We take utmost care while packing the product.
                  At the 
                  same time we ensure that the packing is good such that the
                  items won’t get damaged during transit. Please note that
                  Realhub is 
                  not liable for damages that are caused to the items during
                  transit or transportation.
                </p>
                <p>
                  We will revise your returned product as soon as we receive it
                  and if it follows the guidelines addressed above, we will
                  proceed to 
                  issue a refund of your purchase. Your refund may take a couple
                  of days to process but you will be notified when you receive 
                  your money.
                </p>
                <h4>
                  Shipping
                </h4>
                <p>
                  Realhub is not responsible for return shipping costs. Every
                  shipping has to be paid by the customer, even if the item had
                  free 
                  shipping in the first place, the customer has to pay for the
                  shipping in return.
                </p>
                <h4>
                  Your Consent
                </h4>
                <p>
                  By using our website, registering an account, or making a
                  purchase, you hereby consent to our Return &amp; Refund Policy
                  and 
                  agree to its terms.
                </p>
                <h4>
                  Changes To Our Return &amp; Refund Policy
                </h4>
                <p>
                  Should we update, amend or make any changes to this document
                  so that they accurately reflect our Service and policies.
                </p>
                <p>
                  Unless otherwise required by law, those changes will be
                  prominently posted here. Then, if you continue to use the
                  Service, you 
                  will be bound by the updated Return &amp; Refund Policy. If
                  you do not want to agree to this or any updated Return &amp;
                  Refund Policy, 
                  you can delete your account.
                </p>
                <h4>
                  Contact Us
                </h4>
                <p>
                  If, for any reason, You are not completely satisfied with any
                  good or service that we provide, don&apos;t hesitate to
                  contact us and we 
                  will discuss any of the issues you are going through with our
                  product.
                </p> 
                <p>
                  Via Email: support@realhub.co.ke
                </p> 
                <p>
                  Via Phone Number: +254 114 140 882
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppFooter from "../components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>
